import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class PushNotificationService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/push-notification`;

      }
      paginate(data, index = null) {
            let url = `${this.#api}`;

            if (index)
                  data.page = index;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }

      update(id,  data) {
            let url = `${this.#api}/${id}/update`
            return apiService.post(url, data);

      }

      store( data) {
            let url = `${this.#api}`;
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }
}
