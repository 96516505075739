<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Manage push notifications
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\  Push notification
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <v-btn   v-if="checkIsAccessible('push-notification', 'create')"
                         @click="__add()"
                         class="mt-4 btn btn-primary  mr-1"
                         dark>
                    <i class="fa fa-plus"></i>
                    Send push notification
                  </v-btn>

                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="3">
                    <v-text-field label="Title"
                                  type="text"
                                  dense
                                  outlined
                                  v-model="search.title"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select label="Scheduled"
                              :items="schedule"
                              item-text="title"
                              item-value="value"
                              dense
                              outlined
                              v-model="search.scheduled"></v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select label="Notified"
                              :items="notified"
                              item-text="title"
                              item-value="value"
                              dense
                              outlined
                              v-model="search.is_notified"></v-select>
                  </v-col>

                  <v-col cols="12" v-if="search.scheduled == 'yes'" md="3">
                    <v-menu
                        v-model="date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search.scheduled_at"
                            label="Scheduled at"
                            append-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="search.scheduled_at"
                          @input="date = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                              item-value="value"
                              v-model="search.active"></v-select>
                  </v-col>

                  <v-col cols="12" :md="search.scheduled == 'yes' ? 9 : 12" class="text-right">
                    <v-btn
                        @click="getNotifications()"
                        class="btn btn-primary btn-search "
                        :loading="loading"
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading">
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25">
                </v-skeleton-loader>
                <table class="table">
                  <thead>
                  <tr class="">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Description</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(notification, index) of push_notfications" :key="index">
                    <td class="wrap-column px-3">
                      <a href="#" @click="editNotification(notification.id)" class="mr-2"> {{
                          notification.title || 'N/A' }}</a> &nbsp;&nbsp;
                      <i class="fas fa-circle"
                         :class="notification.is_active?'dot-active':'dot-inactive'"></i>
                    </td>
                    <td class="wrap-column px-3">{{ notification.description ? notification.description.substring(0,80) : 'N/A'}}</td>
                    <td class="wrap-column px-3">{{ notification.notification_type_text  || 'N/A' }}</td>
                    <!--                                            <td>-->
                    <!--                      <span-->
                    <!--                              :class="notification.is_notified?'badge badge-success':'badge badge-warning'">{{ notification.is_notified ? 'Notified' : 'Pending' }}</span>-->
                    <!--                                            </td>-->
                    <!--                                            <td>-->
                    <!--                      <span-->
                    <!--                              :class="notification.is_read?'badge badge-success':'badge badge-warning'">{{ notification.is_read ? 'Read' : 'Pending' }}</span>-->
                    <!--                                            </td>-->


                    <td class="pr-0 text-left">
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('push-notification', 'edit')">
                            <a href="#" class="navi-link"
                               @click="editNotification(notification.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                              <span class="navi-text">  Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('push-notification', 'delete')">
                            <a href="#" class="navi-link"
                               @click="deletePopup(notification.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                              <span class="navi-text">  Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                    <tr v-if="push_notfications.length == 0">
                      <td class="px-3 text-center" colspan="4">
                        <strong>No data available to display.</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <b-pagination
                    v-if="push_notfications.length > 0"
                    class="pull-right mt-7"
                    @input="getNotifications"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>
            </div>
        </div>
        </div>
      </div>
        <create-or-update  ref="push-notification"
                           @refresh_notification="getNotifications" > </create-or-update>

    </v-app>
</template>
<script>
    import CreateOrUpdate from './CreateOrUpdate'
    import PushNotificationService from "@/core/services/push-notification/PushNotificationService";
    import Vue from "vue";

    const pushNotificationService = new PushNotificationService();

    export default {
        name: "Push-Notification",
        components: {CreateOrUpdate},
        data() {
            return {
                push_notfications: [],
                active_statuses: [
                    {title: 'Active', value: "active"},
                    {title: 'Inactive', value: "in_active"}
                ],
                schedule: [
                    {title: 'Scheduled', value: 'yes'},
                    {title: 'Not Scheduled', value: 'no'}
                ],
                notified: [
                    {title: 'Yes', value: 'yes'},
                    {title: 'No', value: 'no'}
                ],
                dialog: false,
                loading: false,
                date: false,
                push_notifications_id: null,
                page: null,
                total: null,
                perPage: null,
                search: {
                    active: "active",
                    is_notified: 'no',
                    scheduled_at: null
                }
            };
        },

        mounted() {
            this.getNotifications();
        },
        methods: {
            format_status: function (value) {
                if (!value.includes('_')) return  value
                value = value.split('_').join(' ')
                return value;
            },
            __add() {
                this.$refs["push-notification"].showModal();
            },
            editNotification(id) {
                this.$refs["push-notification"].showModal(id);
            },
            getNotifications() {
              this.loading=true;
                pushNotificationService.paginate(this.search).then(response => {
                    this.push_notfications = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading=false;
                }).catch((err) => {
                  this.loading = false;
                }).finally(()=>{
                  this.loading = false;
                });
            },


            deletePopup(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            pushNotificationService
                                .delete(id)
                                .then(response => {
                                    this.$snotify.success("Information deleted");
                                    this.getNotifications();
                                })
                                .catch(error => {
                                });
                        }
                    }
                });
            }, openDialog() {
                this.dialog = true
            }, closeDialog() {
                this.dialog = false
                this.popups_id = null
                this.getNotifications()
            }
        }
    };
</script>
