<template>
    <v-app>
        <v-dialog
                v-model="dialog"
                max-width="700"
        >
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{`${edit?'Update':"Add"} push notification`}}
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <div class="row">
                        <div class="col-12 form-group">
                            <v-text-field outlined dense label="Title" v-model="push_notification.title"></v-text-field>
                            <span class="text-danger" v-if="$v.push_notification.title.$error">This information is required</span>
                        </div>
                        <div class="col-12 form-group">
                            <v-text-field outlined dense label="Link" v-model="push_notification.link"></v-text-field>
                        </div>
                        <div class="col-12 form-group">
                            <v-textarea outlined dense label="Description"
                                        v-model="push_notification.description"></v-textarea>
                            <span class="text-danger" v-if="$v.push_notification.description.$error">This information is required</span>

                        </div>
                        <div class="col-12 form-group">
                            <v-file-input   prepend-icon=""
                                            prepend-inner-icon="mdi-file"
                                    outlined dense v-model="push_notification.image"
                                    @change="checkSize($event)" label="Image"></v-file-input>
                            <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>

                        </div>
                        <div class="col-12 form-group">
                            <v-select
                                    outlined
                                    dense
                                    :items="options"
                                    item-value="value"
                                    item-text="name"
                                    v-model="push_notification.notification_type"
                                    @change="checkNotifyAll()"
                                    label="Select option"
                            >
                            </v-select>
                            <span class="text-danger" v-if="$v.push_notification.notification_type.$error">This information is required</span>

                        </div>


                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'single'">
                            <v-autocomplete
                                    v-model="selectedUser"
                                    :items="users"
                                    :loading="isBusy"
                                    outlined
                                    dense
                                    :search-input.sync="searchName"
                                    item-text="display_text"
                                    label="User CRN or email"
                                    return-object
                            ></v-autocomplete>

                        </div>
                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'single'">

                                          <span class="text-danger" v-if="error">
                                              {{errorMessage}}
                                          </span>
                        </div>
                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'multiple'">
                            <v-autocomplete
                                    v-model="selectedMultipleUser"
                                    :items="users"
                                    :loading="isBusy"
                                    outlined
                                    dense
                                    multiple
                                    :search-input.sync="searchName"
                                    item-text="display_text"
                                    label="User CRN or email"
                                    return-object
                            ></v-autocomplete>
                            <span class="text-danger" v-if="error">
                                              {{errorMessage}}
                                          </span>
                            <!--              <span class="text-danger" v-if="$v.push_notification.single_device_token.$error">Type is required</span>-->
                        </div>
                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'topic'">

                            <v-select
                                    v-model="push_notification.topic"
                                    :items="notification_topics"
                                    item-value="id"
                                    item-text="title"
                                    outlined dense label="Topic">
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.title }} -{{ data.item.academic_class.title }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.title }} -{{ data.item.academic_class.title }}
                                </template>
                            </v-select>
                            <!--              <span class="text-danger" v-if="$v.push_notification.single_device_token.$error">Type is required</span>-->
                        </div>
                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'class'">


                            <v-autocomplete
                                    :loading="classLoading" outlined dense
                                    :items="academic_class"
                                    v-model="selectedClass"
                                    multiple
                                    chips
                                    item-text="display_text"
                                    item-value="unique_no"
                                    label="Select class"
                                    :search-input.sync="searchClass"
                            ></v-autocomplete>
<!--                            <v-select :loading="classLoading" outlined dense-->
<!--                                      :items="academic_class"-->
<!--                                      v-model="selectedClass"-->
<!--                                      multiple-->
<!--                                      chips-->
<!--                                      item-text="display_text"-->
<!--                                      item-value="unique_no"-->
<!--                                      label="Select Class">-->


<!--                            </v-select>-->
                            <!--              <span class="text-danger" v-if="$v.push_notification.single_device_token.$error">Type is required</span>-->
                        </div>
                        <div class="col-12 form-group" v-if="push_notification.notification_type== 'program'">

                            <v-select :loading="programLoading"
                                      outlined dense
                                      :items="programs"
                                      v-model="push_notification.topic"
                                      item-text="title"
                                      item-value="id" label="Select program">
                                <template slot="selection" slot-scope="data">
                                    <!-- HTML that describe how select should render selected items -->
                                    {{ data.item.title }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <!-- HTML that describe how select should render items when the select is open -->
                                    {{ data.item.title }} - {{ data.item.title_text }}
                                </template>
                            </v-select>
                            <!--              <span class="text-danger" v-if="$v.push_notification.single_device_token.$error">Type is required</span>-->
                        </div>


                        <div class="col-3 form-group">
                          Status
                            <v-switch
                                  :label="push_notification.is_active?'Active':'Inactive'"
                                    v-model="push_notification.is_active">
                            </v-switch>
                        </div>
                        <div class="col-3 form-group">
                          Is scheduled ?
                            <v-switch
                                    v-model="push_notification.scheduled"
                                    :label="push_notification.scheduled?'Yes':'No'"
                            >
                            </v-switch>
                        </div>
                        <div class="col-12 form-group" v-if="push_notification.scheduled">
                            <strong class="mt-0">Event Start Date Time</strong>
                            <datetime class="form-control border-dark"
                                      value-zone="Asia/Kathmandu"
                                      placeholder="Event start dateTime"
                                      :minute-step="15"
                                      format="yyyy-MM-dd hh:mm:ss"
                                      use12-hour
                                      type="datetime"
                                      v-model="push_notification.schedule_at">
                                <label for="startDate" slot="before"><i class="fas fa-calendar-alt"></i></label>
                            </datetime>
                        </div>
                        <div class="col-6 form-group">
                            <img
                                    v-if="push_notification.image_path"
                                    :src="push_notification.image_path['thumb']"
                                    alt
                                    class="mt-5"
                            />
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="float-right">

                        <v-btn
                                class="btn  btn-standard text-gray cancel-btn"
                                depressed
                                @click="hideModal"
                        >Cancel
                        </v-btn>

                        <v-btn v-if="checkIsAccessible('push-notification', 'create')"
                                class="text-white btn btn-primary btn   font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                                :disabled="sizeExceed"
                                @click="createOrUpdate"
                                :loading="isBusy"
                        >Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>

    import {required} from "vuelidate/lib/validators";
    import PushNotificationService from "@/core/services/push-notification/PushNotificationService";
    import {Datetime} from 'vue-datetime';
    import 'vue-datetime/dist/vue-datetime.css'

    import UserService from "@/core/services/user/UserService";
    import NotificationTopicsService from "@/core/services/notification-topics/NotificationTopicsService";
    import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
    import ProgramService from "@/core/services/level/program/ProgramService";

    const userService = new UserService();

    const pushNotificationService = new PushNotificationService();

    const notificationTopicsService = new NotificationTopicsService();
    const academicClassService = new AcademicClassService();
    const programService = new ProgramService();
    export default {
        name: 'push_notification-new',
        props: ["dialog", "push_notifications_id"],
        components: {
            datetime: Datetime
        },
        validations: {
            push_notification: {
                description: {required},
                title: {required},
                notification_type: {required},
            }
        },
        data() {
            return {
                isBusy: false,
                classLoading: false,
                programLoading: false,
                selectedClass: [],
                sizeExceed: false,
                selectedUser: null,
                searchName: null,
                searchClass: null,
                error: false,
                errorMessage: null,
                users: [],
                academic_class: [],
                programs: [],
                device_tokens: [],
                selectedMultipleUser: [],
                notification_topics: [],
                search: {
                    name: '',
                    limit: 25,

                },
                priorities: [
                    {
                        name: 'High',
                        value: 'high'
                    },
                    {
                        name: 'Low',
                        value: 'low'
                    },
                ],
                classSearch:{

                },
                options: [
                    {
                        name: 'Single user',
                        value: 'single'
                    },
                    {
                        name: 'Multiple user',
                        value: 'multiple'
                    },
                    {
                        name: 'Notify all',
                        value: 'notify_all'
                    },
                    {
                        name: 'Notify by program',
                        value: 'program'
                    },
                    {
                        name: 'Notify by class',
                        value: 'class'
                    },
                ],
                push_notification: {
                    id: null,
                    title: null,
                    link: null,
                    image: null,
                    description: null,
                    notification_type: null,
                    single_device_token: null,
                    device_tokens: null,
                    topic: null,
                    priority: 'high',
                    notify_all: 0,
                    scheduled: 0,
                    is_active: 1,
                },
                edit: false,
                user: null

            };
        }, updated() {
            if (this.push_notifications_id)
                if (!this.push_notification.id) {
                    this.getNotification(this.push_notifications_id)
                }
        },
        mounted() {
            // this.getUsers();
            this.getNotificationsTopics();
        },
        watch: {
            push_notifications_id(newValue, oldValue) {
                this.resetForm();
                this.getNotification(newValue)
            },
            searchName(newValue, oldValue) {
                this.search.name = newValue;
                // Lazily load input items
                this.getUsers();

            },
            searchClass(newValue, oldValue) {
                this.classSearch.title = newValue;
                // Lazily load input items
                this.getClass();

            },
            selectedUser(user) {
                if (user.push_notification_id) {
                    this.error = false;
                    this.errorMessage = '';
                    this.push_notification.single_device_token = user.push_notification_id;
                } else {
                    this.error = true;
                    this.errorMessage = 'User Single Device Tokken is Not Available'
                }
            },

            selectedMultipleUser(users) {
                users.map(user => {
                    if (user.push_notification_id) {
                        this.error = false;
                        this.errorMessage = '';
                        if (!this.device_tokens.includes(user.push_notification_id)) this.device_tokens.push(user.push_notification_id)
                        this.push_notification.device_tokens = this.device_tokens.join(',')
                    } else {
                        this.error = true;
                        this.errorMessage = 'User Single Device Tokken is Not Available'
                    }
                })

            }, selectedClass(academic_class) {
                this.push_notification.topic = null
                this.push_notification.topic = academic_class.join(',')
            },

            //
            // search(val) {
            //     // Items have already been loaded
            //     if (this.users.length > 0) return
            //
            //     // Items have already been requested
            //     if (this.isBusy) return
            //
            //     this.getUser();
            // },
        },
        computed: {
            id() {
                return this.$route.params.id ? this.$route.params.id : false;
            }
        },
        methods: {
            getClass() {
                this.classLoading = true;
                academicClassService.paginate(this.classSearch).then(response => {
                    this.academic_class = response.data.data;
                    this.academic_class.map(ele => {
                        if (ele.unique_no && ele.unique_no != undefined && ele.unique_no != null) {
                            ele.display_text = `${ele.title} - ${ele.grade_title} - ${ele.program.title }`
                            this.academic_class.push(ele)
                        }
                    })
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.classLoading = false;

                });
            },
            showModal(id = null) {
                if (id) {
                    this.edit = true
                    this.getNotification(id)
                }
                this.dialog = true;
            },
            hideModal() {
                this.resetForm();
                this.dialog = false;
            },
            getPrograms() {
                this.programLoading = true;
                programService.all().then(response => {
                    this.programs = response.data;
                }).catch((err) => {
                    // console.log(err)
                }).finally(() => {
                    this.programLoading = false;

                });
            },
            getUsers() {
                this.isBusy = true;
                userService.paginate(this.search).then(response => {
                    this.isBusy = false;
                    this.users = response.data.data;
                    this.users.map(ele => {
                        if (ele.personal_id && ele.personal_id != undefined && ele.personal_id != null) {
                            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`
                            this.users.push(ele)
                        }
                    })
                    // this.page = response.data.meta.current_page;
                    // this.total = response.data.meta.total;
                    // this.perPage = parseInt(response.data.meta.per_page);
                    // alert(this.length)
                });
            },
            getNotificationsTopics() {
                notificationTopicsService
                    .paginate()
                    .then(response => {
                        this.notification_topics = response.data.data;
                    })
                    .catch(error => {
                        // console.log(error);
                    });
            },

            checkNotifyAll() {
                this.push_notification.notification_type == 'notify_all' ?
                    this.push_notification.notify_all = true :
                    this.push_notification.notify_all = false;

                if (this.push_notification.notification_type == 'class') {
                    // this.getClass();
                }
                if (this.push_notification.notification_type == 'program') {
                    this.getPrograms();
                }
            },
            getNotification(id) {
                pushNotificationService
                    .show(id)
                    .then(response => {
                        this.edit = true
                        this.push_notification = response.data;
                        delete this.push_notification.image;
                        this.push_notification.is_active ? this.push_notification.is_active = true : false;
                        if (this.push_notification.schedule_at)
                            this.push_notification.schedule_at = this.$moment(this.push_notification.schedule_at).toISOString();

                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                // console.log(mbSize)
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },

            savePushNotification(fd) {
                this.isBusy = true;
                pushNotificationService
                    .store(fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information added");
                        this.hideModal();

                    }).catch((error) => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                });
                ;
            },

            createOrUpdate() {
                this.$v.push_notification.$touch();
                if (this.$v.push_notification.$error) {
                    setTimeout(() => {
                        this.$v.push_notification.$reset();
                    }, 3000);
                } else {
                    let fd = this.generateFd();
                    if (this.edit) {
                        this.updateNotification(fd);
                    } else {
                        this.savePushNotification(fd);
                    }
                }
            },
            updateNotification(fd) {
                this.isBusy = true;
                pushNotificationService
                    .update(this.push_notification.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");
                        this.hideModal();
                    }).catch((error) => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                });
                ;
            },
            generateFd() {
                let fd = new FormData();
                for (let key in this.push_notification) {
                    if (
                        key == "image" &&
                        this.push_notification["image"] &&
                        this.push_notification["image"] != null
                    ) {
                        fd.append("image", this.push_notification[key]);
                    } else if (key == "notify_all") {
                        fd.append("notify_all", this.push_notification['notify_all'] == true ? 1 : 0);
                    } else if (key == "scheduled") {
                        fd.append("scheduled", this.push_notification['scheduled'] == true ? 1 : 0);
                    } else if (key == "is_active") {
                        fd.append("is_active", this.push_notification['is_active'] == true ? 1 : 0);
                    } else if (
                        this.push_notification[key] != undefined &&
                        this.push_notification[key] != null &&
                        this.push_notification[key] != null
                    ) {
                        fd.append(key, this.push_notification[key]);
                    }
                }
                return fd;
            }, resetForm() {
                this.push_notification = {
                    id: null,
                    title: null,
                    link: null,
                    image: null,
                    description: null,
                    notification_type: null,
                    single_device_token: null,
                    device_tokens: null,
                    topic: null,
                    priority: 'high',
                    notify_all: 0,
                    scheduled: 0,
                    is_active: 1,
                }
                this.edit = false;
                this.$emit("refresh_notification");
            }
        }
    };
</script>

<style>
    .v-application--wrap {
        min-height: 0 !important;
    }
</style>
